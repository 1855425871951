import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "@layout/Select2";
import { loadUsers } from "@actions/users";
import { hideModal } from "@actions";
import Datetime from "react-datetime";
import moment from "moment";
import {
  getSponsors,
  getPersons,
  createSponsorship,
  updateSponsorship,
} from "@actions/sponsors";
import { createFastPayment, updateFastPayment } from "@actions/payments";
import Input from "@layout/Input";
import SubmitContainer from "@layout/SubmitContainer";

class PaymentType extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mainType: "",
      way: "",
      amount: "",
      transactionDate: "",
      transactionId: "",
      notes: "",
      sponsorOptions: "",
      sponsorId: "",
      personId: "",
      personOptions: "",
      sponsorshipType: "",
      sponsorshipId: "",
      paymentId: "",
      sponsorshipExpirationDate: "",
      errors: {
        amount: false,
        sponsorId: false,
        personId: false,
        sponsorshipType: false,
        sponsorshipExpirationDate: false,
      },
    };
  }
  UNSAFE_componentWillMount() {
    this.props
      .loadUser(this.props.eventId, this.props.userId)
      .then(({ data }) => {
        this.user = data;
        this.payment = this.user.subscription?.payment || {};
        this.sponsorship = this.user.subscription?.sponsorship || {};

        const hasPayment =
          this.user.subscriptionSumPaid ||
          this.user.subscriptionSumDiscount ||
          Object.keys(this.payment).length;
        const hasSponsorPayment =
          this.user.subscriptionSponsoredBy.length ||
          Object.keys(this.sponsorship).length;
        this.defaultMainType = hasPayment
          ? "normal"
          : hasSponsorPayment
          ? "sponsorship"
          : "";
        const {
          way,
          amount,
          transactionDate,
          transactionId,
          paymentId,
          notes,
          sponsorId,
          sponsorOptions,
          personId,
          personOptions,
          sponsorshipType,
          sponsorshipExpirationDate,
          sponsorshipId,
        } = this.getStateInitialState();
        this.setState({
          mainType: this.defaultMainType || "normal",
          way,
          amount,
          transactionDate,
          transactionId,
          paymentId,
          notes,
          sponsorId,
          sponsorOptions,
          personId,
          personOptions,
          sponsorshipType,
          sponsorshipExpirationDate,
          sponsorshipId,
        });
      });
  }
  getStateInitialState() {
    if (this.defaultMainType == "normal" || this.defaultMainType == "") {
      const {
        way = "",
        amount = this.user.subscriptionPrice,
        transactionDate = moment().format("YYYY-MM-DD HH:mm"),
        transactionId = "",
        notes = "",
        id = "",
      } = this.payment;
      return {
        way,
        amount,
        transactionDate,
        transactionId,
        notes,
        paymentId: id,
      };
    }
    this.loadSponsors();
    const {
      amount = this.user.subscriptionPrice,
      sponsorOptions = "",
      orgSponsorId = "",
      orgSponsorPersonId,
      personOptions,
      paymentType = "",
      paymentDate = "",
      id = "",
    } = this.sponsorship;
    if (orgSponsorId) this.loadPersons(orgSponsorId);

    let sponsorshipExpirationDate = "";

    if (paymentDate && paymentDate.tz && paymentDate.timezone) {
      const parsedDate = moment.tz(
        paymentDate.tz,
        "YYYY-MM-DD HH:mm:ss",
        paymentDate.timezone
      );
      if (parsedDate.isValid())
        sponsorshipExpirationDate = parsedDate.format("DD-MM-YYYY");
    }

    return {
      amount,
      sponsorOptions,
      sponsorId: orgSponsorId,
      personId: orgSponsorPersonId,
      personOptions,
      sponsorshipType: paymentType,
      sponsorshipExpirationDate,
      sponsorshipId: id,
    };
  }
  validateField = (fieldName, value) => {
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        [fieldName]: !value,
      },
    }));
  };

  loadSponsors() {
    this.props.loadSponsors(this.props.orgId).then(({ data }) => {
      const sponsorOptions = {};
      for (const sponsor of data) {
        sponsorOptions[sponsor.id] = sponsor.name;
      }
      this.setState({ sponsorOptions });
    });
  }
  loadPersons(sponsorId) {
    this.props.loadPersons(this.props.orgId, sponsorId).then(({ data }) => {
      const personOptions = {};
      for (const person of data) {
        personOptions[person.id] = `${person.firstName} ${person.lastName}`;
      }
      this.setState({ personOptions });
    });
  }
  handleSubmit = () => {
    const {
      amount,
      sponsorId,
      personId,
      sponsorshipType,
      sponsorshipExpirationDate,
      mainType,
      way,
      transactionDate,
      transactionId,
      notes,
      paymentId,
    } = this.state;

    const errors = {
      amount: !amount,
      sponsorId: !sponsorId,
      personId: !personId,
      sponsorshipType: !sponsorshipType,
      sponsorshipExpirationDate:
        sponsorshipType === "deposit" && !sponsorshipExpirationDate,
    };

    this.setState({ errors });

    const hasErrors = Object.values(errors).some((e) => e);
    if (mainType === "sponsorship") {
      if (hasErrors) return;
      this.defaultMainType === "sponsorship"
        ? this.props.updateSponsorship(
            this.props.eventId,
            this.state.sponsorshipId,
            this.props.policyId,
            {
              orgSponsorId: sponsorId,
              orgSponsorPersonId: personId,
              amount: amount,
              paymentType: sponsorshipType,
              paymentDate: sponsorshipExpirationDate
                ? moment(sponsorshipExpirationDate, "DD-MM-YYYY").format(
                    "YYYY-MM-DD"
                  )
                : "",
            }
          )
        : this.props.createSponsorship(
            this.props.eventId,
            this.props.policyId,
            {
              orgSponsorId: sponsorId,
              orgSponsorPersonId: personId,
              type: "registration",
              referenceId: this.user.id,
              amount: amount,
              eventUserId: this.user.id,
              paymentType: sponsorshipType,
              paymentDate: sponsorshipExpirationDate
                ? moment(sponsorshipExpirationDate, "DD-MM-YYYY").format(
                    "YYYY-MM-DD"
                  )
                : "",
            }
          );
    } else {
      if (errors.amount) return;
      this.defaultMainType === "normal"
        ? this.props.updatePayment(
            this.props.eventId,
            paymentId,
            this.props.policyId,
            {
              amount: amount,
              way: way,
              type: "registration",
              eventUserId: this.user.id,
              transactionId: transactionId,
              transactionDate: transactionDate,
              notes: notes,
            }
          )
        : this.props.createPayment(this.props.eventId, this.props.policyId, {
            amount: amount,
            way: way,
            type: "registration",
            eventUserId: this.user.id,
            transactionId: transactionId,
            transactionDate: transactionDate,
            notes: notes,
          });
    }
  };

  render() {
    const { errors } = this.state;

    if (!this.user) return <div>Loading...</div>;
    const isDisabledPaymet = ["card", "paypal"].includes(this.state.way);
    return (
      <div className="form-container">
        <h2>{this.defaultMainType ? "Edit Last Payment" : "Create Payment"}</h2>
        <div className="form-group">
          <label>Details</label>
          <div className="form-info">
            <p>{`User: ${this.user.id} ${this.user.info.firstName} ${this.user.info.lastName}`}</p>
            <p>{`Pricing Details: ${this.user.subscriptionDetails}`}</p>
            <p>{`Total Amount: ${this.user.subscriptionPrice?.toFixed(2)}`}</p>
            <p>{`Due Amount: ${this.user.subscriptionDueAmount?.toFixed(
              2
            )}`}</p>
          </div>
        </div>
        <div className="form-group">
          <label>Payment Type (*)</label>
          <Select
            disabled={this.defaultMainType}
            options={{
              normal: "Normal Payment",
              sponsorship: "Sponsorship",
            }}
            onChange={(val) => {
              if (val === "sponsorship") this.loadSponsors();
              this.setState({ mainType: val });
            }}
            isClearable={false}
            isSearchable={false}
            value={this.state.mainType}
          />
        </div>
        {this.state.mainType === "normal" && (
          <>
            <div className={`form-group ${errors.amount ? "has-error" : ""}`}>
              <label>Transaction Amount (*)</label>
              <Input
                type="number"
                placeholder="Enter the transaction amount..."
                value={this.state.amount}
                disabled={isDisabledPaymet}
                onChange={(e) => {
                  if (!isFinite(e.target.value)) return;
                  this.validateField("amount", e.target.value);
                  this.setState({ amount: e.target.value });
                }}
              />
              {errors.amount && (
                <div className="help-block">This field is required</div>
              )}
            </div>
            <div className="form-group">
              <label>Payment Method (*)</label>
              <Select
                disabled={isDisabledPaymet}
                options={
                  isDisabledPaymet
                    ? {
                        card: "Credit/Debit Card",
                        paypal: "PayPal",
                      }
                    : {
                        cash: "Cash",
                        bank: "Bank Transfer",
                        pos: "POS transaction",
                        discount: "Discount",
                      }
                }
                onChange={(val) => {
                  this.setState({ way: val });
                }}
                isClearable={false}
                isSearchable={false}
                value={this.state.way}
              />
            </div>
            <div className="form-group">
              <label>Transaction date</label>
              <Datetime
                dateFormat="DD-MM-YYYY HH:mm"
                timeFormat={false}
                value={this.state.transactionDate}
                closeOnSelect={true}
                inputProps={{
                  placeholder: `Transaction date`,
                  readOnly: true,
                }}
                onChange={(val) => {
                  if (!moment(val).isValid()) return;
                  this.setState({
                    transactionDate: val.format("YYYY-MM-DD HH:mm"),
                  });
                }}
              />
            </div>
            <div className="form-group">
              <label>Transaction ID</label>
              <Input
                placeholder="Enter the transaction id if available..."
                value={this.state.transactionId}
                disabled={isDisabledPaymet}
                onChange={(e) => {
                  this.setState({ transactionId: e.target.value });
                }}
              />
            </div>
            <div className="form-group">
              <label>Notes</label>
              <Input
                placeholder="Enter notes to accompany this payment..."
                value={this.state.notes}
                onChange={(e) => {
                  this.setState({ notes: e.target.value });
                }}
              />
            </div>
            <SubmitContainer
              onSubmit={this.handleSubmit}
              onCancel={() => {
                this.props.hideModal();
              }}
            />
          </>
        )}
        {this.state.mainType === "sponsorship" && (
          <>
            <div className={`form-group ${errors.amount ? "has-error" : ""}`}>
              <label>Transaction Amount (*)</label>
              <Input
                type="number"
                placeholder="Enter the sponsored amount..."
                value={this.state.amount}
                onChange={(e) => {
                  if (!isFinite(e.target.value)) return;
                  this.validateField("amount", e.target.value);
                  this.setState({ amount: e.target.value });
                }}
              />
              {errors.amount && (
                <div className="help-block">This field is required</div>
              )}
            </div>

            <div
              className={`form-group ${errors.sponsorId ? "has-error" : ""}`}
            >
              <div>
                <label>Select a Sponsor (*)</label>
                <Select
                  options={this.state.sponsorOptions}
                  onChange={(val) => {
                    this.validateField("sponsorId", val);
                    this.setState({ sponsorId: val });
                    this.loadPersons(val);
                  }}
                  isClearable={false}
                  value={this.state.sponsorId}
                />
                {errors.sponsorId && (
                  <div className="help-block">This field is required</div>
                )}
              </div>
            </div>
            <div className={`form-group ${errors.personId ? "has-error" : ""}`}>
              <div>
                <label>Select a Person (*)</label>
                <Select
                  options={this.state.personOptions}
                  onChange={(val) => {
                    this.validateField("personId", val);
                    this.setState({ personId: val });
                  }}
                  isClearable={false}
                  value={this.state.personId}
                />
                {errors.personId && (
                  <div className="help-block">This field is required</div>
                )}
              </div>
            </div>
            <div
              className={`form-group ${
                errors.sponsorshipType ? "has-error" : ""
              }`}
            >
              <div>
                <label>Type of sponsorship(*)</label>
                <Select
                  options={{ credit: "Credit", deposit: "Deposit" }}
                  onChange={(val) => {
                    this.validateField("sponsorshipType", val);
                    this.setState({ sponsorshipType: val });
                  }}
                  isClearable={false}
                  isSearchable={false}
                  value={this.state.sponsorshipType}
                />
                {errors.sponsorshipType && (
                  <div className="help-block">This field is required</div>
                )}
              </div>
            </div>

            {this.state.sponsorshipType === "deposit" && (
              <div
                className={`form-group ${
                  errors.sponsorshipExpirationDate ? "has-error" : ""
                }`}
              >
                <label>Deposit expiration date(*)</label>
                <Datetime
                  dateFormat={"DD-MM-YYYY"}
                  timeFormat={false}
                  closeOnSelect={true}
                  value={this.state.sponsorshipExpirationDate}
                  inputProps={{
                    placeholder: `Expiration date`,
                  }}
                  onChange={(value) => {
                    if (!moment(value, "DD-MM-YYYY", true).isValid()) return;
                    this.validateField("sponsorshipExpirationDate", value);
                    this.setState({ sponsorshipExpirationDate: value });
                  }}
                />
                {errors.sponsorshipExpirationDate && (
                  <div className="help-block">This field is required</div>
                )}
              </div>
            )}

            <SubmitContainer
              onSubmit={this.handleSubmit}
              onCancel={() => {
                this.props.hideModal();
              }}
            />
          </>
        )}
      </div>
    );
  }
}

PaymentType.propTypes = {
  eventId: PropTypes.number.isRequired,
  orgId: PropTypes.number.isRequired,
  userId: PropTypes.number,
  loadSponsors: PropTypes.func.isRequired,
  sponsorList: PropTypes.any,
  getPersons: PropTypes.func,
  item: PropTypes.object,
  policyId: PropTypes.number,
  reservation: PropTypes.bool,
  createPayment: PropTypes.func,
  updatePayment: PropTypes.func,
  bankSupported: PropTypes.number,
  searchUsers: PropTypes.func,
  loadPersons: PropTypes.func,
  createSponsorship: PropTypes.func,
  updateSponsorship: PropTypes.func,
  loadUser: PropTypes.func,
  hideModal: PropTypes.func,
  advancedUsers: PropTypes.array,
  accessEvents: PropTypes.object,
  registration: PropTypes.bool,
};
const mapStateToProps = (state) => {
  return {
    orgId: state.api.events.edit.data.orgId,
    sponsorList: state.api.sponsors.data,
    ready: state.api.sponsors.ready,
    eventId: state.api.events.edit.data.id,
    bankSupported: state.api.events.edit.data.settings.billing.bank,
    advancedUsers: state.users.advancedUsers.data,
    accessEvents: state.appuser.data.accessEvents,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadSponsors: (orgId) => {
      return dispatch(getSponsors(orgId, { rpp: -1 }));
    },
    loadPersons: (orgId, sponsorId) => {
      return dispatch(getPersons(orgId, sponsorId));
    },
    createSponsorship: (eventId, policyId, data) => {
      return dispatch(createSponsorship(eventId, data)).then(() => {
        dispatch(loadUsers(eventId, policyId));
      });
    },
    updateSponsorship: (eventId, sponsorshipId, policyId, data) => {
      return dispatch(updateSponsorship(eventId, sponsorshipId, data)).then(
        () => {
          dispatch(loadUsers(eventId, policyId));
        }
      );
    },
    createPayment: (eventId, policyId, data) => {
      return dispatch(createFastPayment(eventId, data)).then(() => {
        dispatch(loadUsers(eventId, policyId));
      });
    },
    updatePayment: (eventId, paymentId, policyId, data) => {
      return dispatch(updateFastPayment(eventId, paymentId, data)).then(() => {
        dispatch(loadUsers(eventId, policyId));
      });
    },
    hideModal: () => dispatch(hideModal()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PaymentType);
