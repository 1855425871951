import { connect } from "react-redux";
import SingleSubscription from "./SingleSubscription";
import { addNotification, hideModal } from "@actions";
import {
  getSubscriptions,
  saveSubscription,
  createTypePeriod,
  updateTypePeriod,
  createType,
  createPeriod,
} from "@actions/pricing";

const mapStateToProps = (state, ownProps) => {
  return {
    eventId: ownProps.eventId,
    types: state.api.subscriptions.types.data,
    periods: state.api.subscriptions.periods.data,
    ready:
      state.api.subscriptions.types.ready &&
      state.api.subscriptions.periods.ready,
    mode: ownProps.subscriptionId !== null ? "update" : "create",
    subscription: ownProps.hasOwnProperty("subscriptionId")
      ? [...state.api.subscriptions.list.data].filter(
          (s) => s.id === ownProps.subscriptionId
        )[0]
      : null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubscriptions: (eventId, action, policyId) => {
      dispatch(getSubscriptions(eventId, undefined, action, policyId));
    },
    hideModal: () => {
      dispatch(hideModal());
    },
    saveSubscription: (
      eventId,
      subscriptionId = false,
      data,
      refresh = false,
      policyId
    ) => {
      if (refresh) {
        return dispatch(saveSubscription(eventId, subscriptionId, data)).then(
          () => {
            dispatch(getSubscriptions(eventId, undefined, undefined, policyId));
          }
        );
      } else {
        dispatch(saveSubscription(eventId, subscriptionId, data));
      }
    },
    createTypePeriod: (eventId, data, param, policyId) => {
      return dispatch(createTypePeriod(eventId, data, param)).then(() => {
        dispatch(getSubscriptions(eventId, undefined, param, policyId));
        return null;
      });
    },
    updateTypePeriod: (eventId, data, id, param, policyId) => {
      return dispatch(updateTypePeriod(eventId, data, id, param))
        .then(() => {
          dispatch(getSubscriptions(eventId, undefined, param, policyId));
          dispatch(getSubscriptions(eventId, undefined, undefined, policyId));
        })
        .then(() => {
          dispatch(
            addNotification(
              "Subscription period updated successfully",
              "success"
            )
          );
        });
    },
    createType: (eventId, data) => {
      return dispatch(createType(eventId, data));
    },
    createPeriod: (eventId, data) => {
      return dispatch(createPeriod(eventId, data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleSubscription);
