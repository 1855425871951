import React from "react";
import { connect } from "react-redux";
import {
  getAbstract,
  loadEventSchema,
  createAbstract,
  updateAbstract,
} from "@actions/abstracts";
import PropTypes from "prop-types";
import KMBLoader from "@layout/KMBLoader";
import moment from "moment";
import { createAbstractFile } from "@helpers";
import Breadcrumbs from "@layout/Breadcrumbs";
import { changeTab } from "@actions/editmenu";
import Select2 from "@layout/Select2";
import { loadSeachUsers } from "@actions/users";
import Radio from "@layout/Radio";
import AbstractInstitutes from "@layout/AbstractInstitutes";
import AbstractAuthors from "@layout/AbstractAuthors";
import SearchAndSelect from "@layout/SearchAndSelect";
import SubmitContainer from "@layout/SubmitContainer";
class SingleAbstract extends React.Component {
  constructor(props) {
    super(props);
    this.initialize = this.initialize.bind(this);
    this.state = {
      data: {},
      storedData: {},
      ready: false,
      disabled: true,
      schema: {},
      users: {},
      selectedUser: "",
    };
  }

  changeAuthorsInstitutesIdToIndex(data) {
    data.authors.forEach((author) => {
      author.institutes = author.institutes.map((instituteId) => {
        if (typeof instituteId !== "string") {
          const theInstitute = data.institutes.filter((abstractInstitute) => {
            return abstractInstitute.id == instituteId;
          });
          return String(theInstitute[0].index);
        }
        return instituteId;
      });
    });
  }

  removeInstituteFromAuthors(data, index) {
    data.authors.forEach((author) => {
      for (let i = 0; i < author.institutes.length; i++) {
        if (author.institutes[i] == index) {
          author.institutes.splice(i, 1);
          i--;
        }
      }
    });
    this.reIndexInstitutes(data);
  }

  reIndexInstitutes(data) {
    data.institutes.forEach((institute, index) => {
      this.reIndexAuthorInstitutes(
        data,
        String(institute.index),
        String(index)
      );
      institute.index = String(index);
    });
  }

  reIndexAuthorInstitutes(data, oldIndex, newIndex) {
    data.authors.forEach((author) => {
      if (author.institutes.includes(oldIndex)) {
        author.institutes.splice(
          author.institutes.indexOf(oldIndex),
          1,
          newIndex
        );
      }
    });
  }

  initialize(props) {
    const { getAbstract, loadEventSchema } = props;
    const eventId = props.eventId;
    const abstractId = props.abstractId;
    const promiseArray = [loadEventSchema(eventId)];
    if (abstractId === undefined) return;
    if (abstractId === "create") {
      promiseArray.push(
        (async () => ({
          data: {
            name: "",
            authors: [],
            abstractText: "",
            topics: [],
            institutes: [],
            eventAbstractSchemaTypeId: "",
            meta: [],
          },
        }))()
      );
    } else {
      promiseArray.push(getAbstract(eventId, abstractId));
    }
    Promise.all(promiseArray)
      .then(([schema, abstract]) => {
        if (!abstract.data.id) {
          for (const key in schema.data.meta) {
            abstract.data.meta.push({
              key,
              value: "",
              name: schema.data.meta[key].name,
              type: schema.data.meta[key].type,
            });
          }
        }

        this.changeAuthorsInstitutesIdToIndex(abstract.data);
        this.setState({
          data: abstract.data,
          ready: true,
          storedData: JSON.parse(JSON.stringify(abstract.data)),
          schema: schema.data,
          disabled: abstract.data.id ? this.state.disabled : false,
        });
      })
      .catch((err) => console.error(err));
  }
  componentDidMount() {
    this.initialize(this.props);
  }

  getFileClassName(extension) {
    switch (extension) {
      case "jpg":
      case "png":
      case "gif":
      case "bmp": {
        return "fa-file-image-o";
      }
      case "pdf": {
        return "fa-file-pdf-o";
      }
      case "doc":
      case "docx": {
        return "fa-file-word-o";
      }
      case "xls":
      case "xlsx":
      case "csv": {
        return "fa-file-excel-o";
      }
      default: {
        return "fa-file-o";
      }
    }
  }

  onChangeInstitutes = (newInstitutes, action = {}) => {
    const newData = { data: { ...this.state.data, institutes: newInstitutes } };
    if (newInstitutes[newInstitutes.length - 1].name == "")
      newInstitutes.splice(-1);
    if (action.name == "remove") {
      this.removeInstituteFromAuthors(newData.data, action.index);
    }
    this.setState(newData);
  };

  onChangeAuthors = (newAuthors) => {
    const lastAuthor = newAuthors[newAuthors.length - 1];
    if (
      lastAuthor.firstName == "" &&
      lastAuthor.lastName == "" &&
      lastAuthor.email == "" &&
      !lastAuthor.institutes.length
    )
      newAuthors.splice(-1);
    this.setState({ data: { ...this.state.data, authors: newAuthors } });
  };

  onSubmit = () => {
    const {
      name,
      authors,
      abstractText,
      topics,
      institutes,
      eventAbstractSchemaTypeId,
      meta,
    } = this.state.data;
    const data = {
      name,
      authors,
      abstractText,
      meta: meta
        .map((field) => {
          if (field.type === "yes_no") {
            field.value = field.value === "yes";
          }
          return field;
        })
        .reduce((prev, next) => ({ ...prev, [next.key]: next.value }), {}),
      topics: topics.map((topic) =>
        topic.eventAbstractSchemaTopicId
          ? topic.eventAbstractSchemaTopicId
          : topic
      ),
      institutes: institutes.map((institute, index) => ({
        ...institute,
        index,
      })),
      eventAbstractSchemaTypeId,
    };

    this.setState({ ready: false }, () => {
      this.state.data.id
        ? this.props
            .updateAbstract(this.props.event.id, this.state.data.id, data)
            .then(({ data }) => {
              this.changeAuthorsInstitutesIdToIndex(data);

              return this.setState({
                ready: true,
                data,
                storedData: JSON.parse(JSON.stringify(data)),
                disabled: true,
              });
            })
            .catch(() => this.setState({ ready: true }))
        : this.props
            .createAbstract(this.props.event.id, {
              data: {
                ...data,
                eventUserId: this.state.selectedUser,
                status: "submitted",
              },
            })
            .then(() => {
              this.props.back();
            })
            .catch(() => this.setState({ ready: true }));
    });
  };

  onCancel = () => {
    this.setState({ data: JSON.parse(JSON.stringify(this.state.storedData)) });
  };

  renderJSX(field, index) {
    const schemaOption = this.state.schema?.meta[field.key] || {};
    const label = (
      <label key={index}>{`${field.name}${
        schemaOption.required ? " (*)" : ""
      }`}</label>
    );
    switch (field.type) {
      case "text":
        return (
          <div className="form-group">
            {label}
            <input
              className="form-control"
              value={field.value}
              disabled={this.state.disabled}
              onChange={(e) => {
                const newMeta = [...this.state.data.meta];
                newMeta[index].value = e.target.value;
                this.setState({ data: { ...this.state.data, meta: newMeta } });
              }}
            />
          </div>
        );
      case "textarea":
        return (
          <div className="form-group">
            {label}
            <textarea
              className="form-control"
              value={field.value}
              disabled={this.state.disabled}
              onChange={(e) => {
                const newMeta = [...this.state.data.meta];
                newMeta[index].value = e.target.value;
                this.setState({ data: { ...this.state.data, meta: newMeta } });
              }}
            />
          </div>
        );
      case "dropdown":
        return (
          <div className="form-group">
            {label}
            <Select2
              disabled={this.state.disabled}
              options={(schemaOption.value || []).reduce(
                (prev, next) => ({ ...prev, [next]: next }),
                {}
              )}
              onChange={(val) => {
                const newMeta = [...this.state.data.meta];
                newMeta[index].value = val || "";
                this.setState({ data: { ...this.state.data, meta: newMeta } });
              }}
              placeholder={"Select"}
              value={String(field.value)}
              multi={false}
              isSearchable={false}
            />
          </div>
        );
      case "yes_no":
        return (
          <div className="form-group kmb-radio">
            {label}
            <div
              style={{ minHeight: 40, display: "flex", alignItems: "center" }}
            >
              <Radio
                id={`field-${field.key}`}
                disabled={this.state.disabled}
                label={field.name}
                checked={field.value}
                onChange={(val) => {
                  const newMeta = [...this.state.data.meta];
                  newMeta[index].value = val;
                  this.setState({
                    data: { ...this.state.data, meta: newMeta },
                  });
                }}
              />
            </div>
          </div>
        );
    }
  }

  onChangeUser = (value) => {
    this.setState({ selectedUser: value });
  };
  render() {
    if (!this.state.ready || !Object.keys(this.state.schema).length) {
      return <KMBLoader rows={15} padding={24} height={53} />;
    }
    const { data } = this.state;
    return (
      <>
        <div className="tab-header">
          <div className="left"></div>
          <div className="right">
            {this.state.data.id && (
              <div>
                <button
                  className="btn save"
                  onClick={() =>
                    this.setState({ disabled: !this.state.disabled })
                  }
                >
                  <span
                    style={{ fontSize: 14 }}
                    className="icon icon-edit"
                  ></span>
                  {this.state.disabled ? "Enable Editing" : "Disable Editing"}
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="tab-content tab-singleabstract form-container container">
          <Breadcrumbs
            options={[
              {
                name: "Abstracts",
                action: () => this.props.back(),
              },
              {
                name: this.state.disabled
                  ? `Viewing Abstract ${data.id}`
                  : data.id
                  ? `Editing Abstract ${data.id}`
                  : "Create New",
              },
            ]}
          />
          {!data.id && (
            <div className="flex-group">
              <div className="form-group">
                <label>Participant (*)</label>
                <SearchAndSelect
                  placeholder={"Search participant..."}
                  onSelectChange={this.onChangeUser}
                  selectValue={this.state.selectedUser}
                  onSearch={(query) => {
                    if (!query) this.setState({ selectedUser: "" });
                    return this.props.searchUsers(
                      query,
                      this.props.event.id,
                      this.props.policyIds
                    );
                  }}
                />
              </div>
            </div>
          )}
          {data.id && (
            <div className="statistics">
              <div className="form-group">
                <label>Submitted On</label>
                <p> {moment(data.updatedAt.tz).format("DD-MM-YYYY HH:mm")}</p>
              </div>

              <div className="form-group">
                <label>Status</label>
                <p> {data.status}</p>
              </div>
              <div className="form-group">
                <label>Participant</label>
                <p>
                  {`${data.user.id} ${data.user.info.firstName} ${data.user.info.lastName}, ${data.user.info.email}`}
                </p>
              </div>
              <div
                className="form-group"
                onClick={async () => {
                  if (!this.state.disabled) return;
                  return await createAbstractFile({
                    eventName: this.props.event.name,
                    ...this.state.data,
                  });
                }}
                title="Download all"
                style={{
                  opacity: !this.state.disabled ? 0.5 : 1,
                  cursor: !this.state.disabled ? "not-allowed" : "pointer",
                  textAlign: "center",
                  color: "#57aeff",
                  fontSize: 18,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div>
                  <i className="fa fa-download" aria-hidden="true"></i>
                </div>
                <div
                  style={{
                    fontSize: 14,
                  }}
                >
                  Download Abstract
                </div>
              </div>
            </div>
          )}

          <div className="flex-group">
            <div className="form-group">
              <label htmlFor="">Title (*)</label>
              <textarea
                style={{ minHeight: 80, maxHeight: 160 }}
                disabled={this.state.disabled}
                className="form-control"
                value={data.name}
                onChange={(e) =>
                  this.setState({ data: { ...data, name: e.target.value } })
                }
              />
            </div>
          </div>
          <div className="flex-group">
            <div className="form-group">
              <label htmlFor="">Final Type</label>
              <div className="">
                <Select2
                  disabled={this.state.disabled}
                  options={this.state.schema.types?.reduce(
                    (prev, next) => ({ ...prev, [next.id]: next.name }),
                    {}
                  )}
                  onChange={(val) => {
                    this.setState({
                      data: { ...data, eventAbstractSchemaTypeId: val },
                    });
                  }}
                  placeholder={"Select an Abstract Type"}
                  value={data.eventAbstractSchemaTypeId}
                  multi={false}
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="">Topics</label>
              <div>
                <Select2
                  disabled={this.state.disabled}
                  options={this.state.schema.topics?.reduce(
                    (prev, next) => ({ ...prev, [next.id]: next.name }),
                    {}
                  )}
                  onChange={(val) => {
                    this.setState({ data: { ...data, topics: val } });
                  }}
                  placeholder={"Select Abstract Topics"}
                  value={data.topics.map((topic) =>
                    topic.eventAbstractSchemaTopicId
                      ? String(topic.eventAbstractSchemaTopicId)
                      : topic
                  )}
                  multi={true}
                />
              </div>
            </div>
          </div>
          <div className="flex-group">
            <div className="col-xs-12 form-group">
              <label htmlFor="">Institutes</label>
              <AbstractInstitutes
                disabled={this.state.disabled}
                institutes={data.institutes}
                onChange={this.onChangeInstitutes}
              />
            </div>
          </div>
          <div className="flex-group">
            <div className="col-xs-12 form-group">
              <label htmlFor="">Authors</label>
              <AbstractAuthors
                abstract={data}
                disabled={this.state.disabled}
                authors={data.authors}
                onChange={this.onChangeAuthors}
              />
            </div>
          </div>
          <div className="flex-group">
            <div className="col-sm-12 form-group">
              <label htmlFor="">Abstract Text</label>
              <textarea
                style={{ minHeight: 250, maxHeight: 500, padding: 15 }}
                disabled={this.state.disabled}
                value={data.abstractText}
                onChange={(e) =>
                  this.setState({
                    data: { ...data, abstractText: e.target.value },
                  })
                }
              />
            </div>
          </div>
          <h3>Additional submission fields</h3>
          <div className="flex-group extras">
            {data.meta.map((field, index) => this.renderJSX(field, index))}
          </div>

          {data.abstractFiles?.length > 0 && (
            <div className="files box">
              <label htmlFor="">Attached Files</label>
              <div>
                {data.abstractFiles.map((f) => {
                  return (
                    <div
                      key={`file-${f.id}`}
                      className={`file file-${f.extension}`}
                    >
                      <a href={f.url} target="_blank" rel="noopener noreferrer">
                        <i
                          className={`fa ${this.getFileClassName(f.extension)}`}
                          aria-hidden="true"
                        ></i>
                        {f.fileName}
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {!this.state.disabled && (
            <SubmitContainer
              onCancel={this.onCancel}
              onSubmit={this.onSubmit}
            />
          )}
        </div>
      </>
    );
  }
}

SingleAbstract.propTypes = {
  getAbstract: PropTypes.func.isRequired,
  page: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired,
  createAbstract: PropTypes.func.isRequired,
  loadEventSchema: PropTypes.func.isRequired,
  changeTab: PropTypes.func.isRequired,
  updateAbstract: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
  searchUsers: PropTypes.func,
  policyIds: PropTypes.array.isRequired,
};

export default connect(
  (state) => {
    return {
      policyIds: state.api.accesspolicies.list.data
        .filter((p) => p.type !== "public")
        .map((p) => p.id),
      page: state.page,
      schema: state.api.abstracts.currentSchema.data,
      event: state.api.events.edit.data,
    };
  },
  (dispatch) => ({
    changeTab: (tab, fakeChild) => dispatch(changeTab(tab, fakeChild)),
    getAbstract: (eventId, abstractId) =>
      dispatch(getAbstract(eventId, abstractId)),
    loadEventSchema: (eventId) => dispatch(loadEventSchema(eventId)),
    createAbstract: (eventId, data) => dispatch(createAbstract(eventId, data)),
    updateAbstract: (
      eventId,
      abstractId,
      data,
      bulkJudgeRate,
      multipart,
      getAllAbstracts = false
    ) =>
      dispatch(
        updateAbstract(
          eventId,
          abstractId,
          data,
          bulkJudgeRate,
          multipart,
          getAllAbstracts
        )
      ),
    searchUsers: (queryString, eventId, policyIds) =>
      dispatch(
        loadSeachUsers(eventId, null, {
          search: queryString,
          policyIds: policyIds.join(","),
          rpp: 5,
        })
      ),
  })
)(SingleAbstract);
