import React from "react";
import PropTypes from "prop-types";
import Table from "@layout/Table";
import TableActions from "@layout/TableActions";
import Select from "@layout/Select2";
import Search from "@layout/Search";
import moment from "moment";
import { connect } from "react-redux";
import { addNotification } from "@actions";
import { listGroups, deletePolicyGroup } from "@actions/policyGroups";
import { setGroupId } from "@actions/users";
import SingleGroup from "./SingleGroup";
import { changeTab } from "@actions/editmenu";
import ApiHelper from "@helpers/api-helper";
import { showModal, hideModal } from "@actions";
import { removeAccents } from "../../../../../../helpers";
import { loadPolicy } from "../../../../../../actions/users";

class PolicyGroups extends React.Component {
  constructor(props) {
    super(props);
    this.onRender = this.onRender.bind(this);
    this.filterGroups = this.filterGroups.bind(this);
    this.state = {
      filterGroups: "allGroups",
      policyGroups: [],
      search: "",
      policy: {},
      loading: true,
    };
  }
  filterGroups({ filter, search }, groups) {
    this.setState({
      search,
      policyGroups: groups.filter((group) => {
        const lowerNameWithoutAccents = removeAccents(
          group.name || ""
        ).toLowerCase();
        const lowerSearchWithoutAccents = removeAccents(
          search || ""
        ).toLowerCase();
        const matchSearch =
          search && lowerSearchWithoutAccents
            ? lowerNameWithoutAccents.includes(lowerSearchWithoutAccents)
            : true;

        let matchFilter;
        if (filter === "allGroups") matchFilter = true;
        else if (filter === "myGroups") matchFilter = !group.sysGenerated;
        else if (filter === "pricingGroups")
          matchFilter = group.sysGenerated && group.pricingGroup;
        return matchSearch && matchFilter;
      }),
    });
  }
  onRender(col, item) {
    switch (col.key) {
      case "name": {
        return (
          <div>
            <div style={{ fontWeight: "bold" }}>{item.name}</div>
            <div style={{ color: "var(--black-tone-1)", maxWidth: "50vw" }}>
              {(() => {
                const items = item.filter.filters?.map((f) =>
                  f
                    .map(
                      (q) =>
                        `${q.label} ${q.operator || "="} ${q.rendered_value}`
                    )
                    .join(" AND ")
                );
                if (!items) return "<All Users>";
                return items.length > 1
                  ? `(${items.join(") OR (")})`
                  : items.join("");
              })()}
            </div>
          </div>
        );
      }
      case "view": {
        return (
          <button
            key={`${item.name}-${item.id}`}
            className="btn transparent table-button"
            onClick={() => {
              this.props.setGroupId(
                this.props.eventId,
                this.props.policy.id,
                item.id
              );
              this.props.changeTab("Participants");
            }}
          >
            <span
              style={{
                position: "absolute",
                left: 15,
                top: 15,
                fontSize: 13,
              }}
            >
              <i className="icon-register-participants"></i>
            </span>
            View Participants
          </button>
        );
      }
      case "actions": {
        return (
          <TableActions
            entity="session"
            item={item}
            onEdit={() => {
              this.props.showModal(
                <SingleGroup
                  edit={true}
                  group={item}
                  hideModal={this.props.hideModal}
                  policy={this.state.policy}
                />
              );
            }}
            onDelete={() => {
              this.props.deleteGroup(
                this.props.eventId,
                item.eventPolicyId,
                item.id
              );
            }}
          />
        );
      }
      case "createdAt": {
        return moment(col.value.tz).format(ApiHelper.dateFormat);
      }
      case "updatedAt": {
        return moment(col.value.tz).format(ApiHelper.dateFormat);
      }
    }
  }
  componentDidMount() {
    this.props.loadGroups(this.props.eventId, this.props.policy.id);
    this.props
      .loadPolicy(this.props.eventId, this.props.policy.id)
      .then(({ data: policy }) => {
        this.setState({ policy, loading: false });
      });
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.filterGroups(
      { filter: this.state.filterGroups, search: this.state.search },
      nextProps.policyGroups
    );
    if (nextProps.policy.id !== this.props.policy.id) {
      this.props.loadGroups(this.props.eventId, nextProps.policy.id);
    }
  }

  render() {
    const columns = {
      id: {
        name: "ID",
      },
      name: {
        name: "Name",
      },
      view: {
        name: "",
      },
      actions: {
        name: "",
      },
    };

    return (
      <>
        <div className="tab-header" style={{ paddingTop: 0 }}>
          <div className="left">
            <Select
              options={{
                allGroups: "All Groups",
                myGroups: "My Groups",
                pricingGroups: "Pricing Groups",
              }}
              onChange={(val) => {
                this.setState({ filterGroups: val });
                this.filterGroups(
                  { filter: val, search: this.state.search },
                  this.props.policyGroups
                );
              }}
              isClearable={false}
              isSearchable={false}
              value={this.state.filterGroups}
              hoverBackgroundColor="#f0f0f4"
              color="var(--tab-header-menu)"
              isDropDownButton={true}
              className={"select-button"}
              hasIndicator={false}
              placeholderInsideSelect={false}
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              placeholder={[
                <span key="group-filter" className="icon-filter"></span>,
              ]}
            />
            <Search
              onSearch={(queryString) => {
                this.filterGroups(
                  { filter: this.state.filterGroups, search: queryString },
                  this.props.policyGroups
                );
              }}
              visible={true}
              advancedSearch={true}
              value={this.state.userName}
            />
          </div>
          <div className="right">
            <button
              type="button"
              className="btn create rounded"
              onClick={() =>
                this.props.showModal(
                  <SingleGroup
                    hideModal={this.props.hideModal}
                    policy={this.state.policy}
                  />
                )
              }
            >
              Create
            </button>
          </div>
        </div>

        <div className="tab-content">
          <Table
            filtered={
              this.state.filterGroups !== "allGroups" || this.state.search
            }
            id={"subscriptions"}
            columns={columns}
            items={this.state.policyGroups}
            onRender={(col, item) => this.onRender(col, item, "subscriptions")}
            updating={this.props.fetching || this.state.loading}
          />
        </div>
      </>
    );
  }
}
PolicyGroups.propTypes = {
  eventId: PropTypes.number,
  loadGroups: PropTypes.func,
  loadPolicy: PropTypes.func,
  policyGroups: PropTypes.any,
  deleteGroup: PropTypes.func.isRequired,
  fetching: PropTypes.bool,
  changeTab: PropTypes.func,
  fakeChild: PropTypes.string,
  setGroupId: PropTypes.func,
  showModal: PropTypes.func,
  hideModal: PropTypes.func,
  policy: PropTypes.object,
};
const mapStateToProps = (state) => {
  return {
    eventId: state.api.events.edit.data.id,
    accessPolicies: state.api.accesspolicies.list.data,
    policiesReady: state.api.accesspolicies.list.ready,
    policyGroups: state.api.policyGroups.list.data,
    fetching: state.api.policyGroups.list.fetching,
    fakeChild: state.editmenu.fakeChild,
    policy: state.api.accesspolicies.selectedPolicy,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addNotification: (notification, status) => {
      dispatch(addNotification(notification, status));
    },
    loadGroups: (eventId, policyId) => {
      return dispatch(listGroups(eventId, policyId));
    },
    deleteGroup: (eventId, policyId, groupId) => {
      dispatch(deletePolicyGroup(eventId, policyId, groupId));
    },
    changeTab: (tab, fakeChild) => {
      return dispatch(changeTab(tab, fakeChild));
    },
    setGroupId: (eventId, policyId, groupId) =>
      dispatch(setGroupId(eventId, policyId, groupId)),
    showModal: (content) => dispatch(showModal(content)),
    hideModal: () => dispatch(hideModal()),
    loadPolicy: (eventId, policyId) => {
      return dispatch(
        loadPolicy(policyId, eventId, { dropdownValuesAsObject: true })
      );
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PolicyGroups);
