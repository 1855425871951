import React from "react";
import Form from "@components/form";
import PropTypes from "prop-types";

const Options = (props) => {
  return (
    <div className="form-container tab-content">
      <Form
        componentName="events"
        formName="options"
        value={props.event}
        onSubmit={(data) => {
          props.updateEvent(props.event.id, data.value);
        }}
        onCancel={() => {}}
        onChange={({ key, value }) => {
          props.adjustMenu(key, value);
        }}
      />
    </div>
  );
};

Options.propTypes = {
  event: PropTypes.object.isRequired,
  updateEvent: PropTypes.func.isRequired,
  adjustMenu: PropTypes.func.isRequired,
};

export default Options;
