import { api } from "@actions/ajax";
import { addNotification } from "@actions";

export const getReservations =
  (eventId, meta = { search: "" }) =>
  (dispatch) =>
    dispatch(
      api({
        endpoint: "reservations",
        action: "listReservations",
        params: { eventId },
        query: `?search=${meta.search}`,
      })
    );

export const deleteReservation =
  (eventId, hotelId, reservationId) => (dispatch) =>
    dispatch(
      api({
        endpoint: "reservations",
        action: "deleteReservation",
        params: { eventId, hotelId, reservationId },
        body: {
          data: [],
        },
      })
    );

export const getUserById = (eventId, userId) => (dispatch) => {
  if (userId.toString().length > 0) {
    return dispatch(
      api({
        endpoint: "users",
        action: "get",
        params: { eventId, userId },
        query: "?_404=false&info=true",
      })
    );
  }
};

export const createReservation =
  (eventId, hotelId, hotelRoomId, dates, notes, userId, adminNotes) =>
  (dispatch) =>
    dispatch(
      api({
        endpoint: "reservations",
        action: "createReservation",
        params: { eventId, hotelId },
        body: {
          data: {
            startDate: dates.from,
            endDate: dates.to,
            notes,
            orgHotelRoomId: hotelRoomId,
            eventUserId: userId,
            adminNotes,
          },
        },
      })
    ).then(() => dispatch(getReservations(eventId)));
export const updateReservation =
  (eventId, hotelId, reservationId, dates, notes, adminNotes) => (dispatch) =>
    dispatch(
      api({
        endpoint: "reservations",
        action: "updateReservation",
        params: { eventId, hotelId, reservationId },
        body: {
          data: {
            startDate: dates.from,
            endDate: dates.to,
            notes,
            adminNotes,
          },
        },
      })
    )
      .then(() => dispatch(getReservations(eventId)))
      .then(() =>
        dispatch(addNotification("Reservation updated successfully", "success"))
      );
