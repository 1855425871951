import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "@layout/Select2";
import { hideModal } from "@actions";
import Datetime from "react-datetime";
import { getReservations } from "@actions/reservations";
import moment from "moment";
import {
  getSponsors,
  getPersons,
  createSponsorship,
  updateSponsorship,
} from "@actions/sponsors";
import { createFastPayment, updateFastPayment } from "@actions/payments";
import Input from "@layout/Input";
import SubmitContainer from "@layout/SubmitContainer";

class PaymentType extends React.Component {
  constructor(props) {
    super(props);
    const hasPayments = Object.keys(this.props.item.payments).length;
    const hasSponsorships = Object.keys(this.props.item.sponsorships).length;
    this.defaultMainType = hasPayments
      ? "normal"
      : hasSponsorships
      ? "sponsorship"
      : "";

    this.state = {
      mainType: this.defaultMainType || "normal",
      way: "",
      amount: "",
      transactionDate: "",
      transactionId: "",
      notes: "",
      sponsorOptions: "",
      sponsorId: "",
      personId: "",
      personOptions: "",
      sponsorshipType: "",
      sponsorshipId: "",
      paymentId: "",
      sponsorshipExpirationDate: "",
      ...this.getStateInitialState(),
    };
  }
  getStateInitialState() {
    if (this.defaultMainType == "normal" || this.defaultMainType == "") {
      const {
        way = "",
        amount = this.props.item.totalPrice,
        transactionDate = moment().format("YYYY-MM-DD HH:mm"),
        transactionId = "",
        notes = "",
        id = "",
      } = this.props.item.payments;
      return {
        way,
        amount,
        transactionDate,
        transactionId,
        notes,
        paymentId: id,
      };
    }
    this.loadSponsors();
    const {
      amount = this.props.item.totalPrice,
      sponsorOptions = "",
      orgSponsorId = "",
      orgSponsorPersonId,
      personOptions,
      paymentType = "",
      paymentDate = "",
      id = "",
    } = this.props.item.sponsorships;
    if (orgSponsorId) this.loadPersons(orgSponsorId);

    return {
      amount,
      sponsorOptions,
      sponsorId: orgSponsorId,
      personId: orgSponsorPersonId,
      personOptions,
      sponsorshipType: paymentType,
      sponsorshipExpirationDate: paymentDate
        ? moment(paymentDate?.tz, "YYYY-MM-DD").format("DD-MM-YYYY")
        : "",
      sponsorshipId: id,
    };
  }

  loadSponsors() {
    this.props.loadSponsors(this.props.orgId).then(({ data }) => {
      const sponsorOptions = {};
      for (const sponsor of data) {
        sponsorOptions[sponsor.id] = sponsor.name;
      }
      this.setState({ sponsorOptions });
    });
  }
  loadPersons(sponsorId) {
    this.props.loadPersons(this.props.orgId, sponsorId).then(({ data }) => {
      const personOptions = {};
      for (const person of data) {
        personOptions[person.id] = `${person.firstName} ${person.lastName}`;
      }
      this.setState({ personOptions });
    });
  }
  render() {
    const isDisabledPayment = ["card", "paypal"].includes(this.state.way);
    return (
      <div className="form-container">
        <h2>{this.defaultMainType ? "Edit Last Payment" : "Create Payment"}</h2>
        <div className="form-group">
          <label>Details</label>
          <div className="form-info">
            <p>{`User: ${this.props.item.eventUserId} ${this.props.item.eventUserFullName}`}</p>
            <p>{`Pricing Details: ${moment(this.props.item.endDate.tz).diff(
              moment(this.props.item.startDate.tz),
              "days"
            )} night(s) in ${this.props.item.orgHotelName} - ${
              this.props.item.orgHotelRoomName
            }`}</p>
            <p>{`Total Amount: ${this.props.item.price?.toFixed(2)}`}</p>
            <p>{`Due Amount: ${this.props.item.dueAmount?.toFixed(2)}`}</p>
          </div>
        </div>
        <div className="form-group">
          <label>Payment Type (*)</label>
          <Select
            disabled={this.defaultMainType}
            options={{
              normal: "Normal Payment",
              sponsorship: "Sponsorship",
            }}
            onChange={(val) => {
              if (val === "sponsorship") this.loadSponsors();
              this.setState({ mainType: val });
            }}
            isClearable={false}
            isSearchable={false}
            value={this.state.mainType}
          />
        </div>
        {this.state.mainType === "normal" && (
          <>
            <div className="form-group">
              <label>Transaction Amount (*)</label>
              <Input
                type="number"
                placeholder="Enter the transaction amount..."
                value={this.state.amount}
                disabled={isDisabledPayment}
                onChange={(e) => {
                  if (!isFinite(e.target.value)) return;
                  this.setState({ amount: e.target.value });
                }}
              />
            </div>
            <div className="form-group">
              <label>Payment Method (*)</label>
              <Select
                disabled={isDisabledPayment}
                options={
                  isDisabledPayment
                    ? {
                        card: "Credit/Debit Card",
                        paypal: "PayPal",
                      }
                    : {
                        cash: "Cash",
                        bank: "Bank Transfer",
                        pos: "POS transaction",
                        discount: "Discount",
                      }
                }
                onChange={(val) => {
                  this.setState({ way: val });
                }}
                isClearable={false}
                isSearchable={false}
                value={this.state.way}
              />
            </div>
            <div className="form-group">
              <label>Transaction date</label>
              <Datetime
                dateFormat="DD-MM-YYYY HH:mm"
                timeFormat={false}
                value={this.state.transactionDate}
                closeOnSelect={true}
                inputProps={{
                  placeholder: `Transaction date`,
                  readOnly: true,
                }}
                onChange={(val) => {
                  if (!moment(val).isValid()) return;
                  this.setState({
                    transactionDate: val.format("YYYY-MM-DD HH:mm"),
                  });
                }}
              />
            </div>
            <div className="form-group">
              <label>Transaction ID</label>
              <Input
                placeholder="Enter the transaction id if available..."
                value={this.state.transactionId}
                disabled={this.state.way === "card"}
                onChange={(e) => {
                  this.setState({ transactionId: e.target.value });
                }}
              />
            </div>
            <div className="form-group">
              <label>Notes</label>
              <Input
                placeholder="Enter notes to accompany this payment..."
                value={this.state.notes}
                onChange={(e) => {
                  this.setState({ notes: e.target.value });
                }}
              />
            </div>
            <SubmitContainer
              onSubmit={() => {
                this.defaultMainType === "normal"
                  ? this.props.updatePayment(
                      this.props.eventId,
                      this.props.item.payments.id,
                      {
                        amount: this.state.amount,
                        way: this.state.way,
                        type: "accomodation",
                        typeId: this.props.item.id,
                        eventUserId: this.props.item.eventUserId,
                        transactionId: this.state.transactionId,
                        notes: this.state.notes,
                        transactionDate: this.state.transactionDate
                          ? moment(
                              this.state.transactionDate,
                              "DD-MM-YYYY HH:mm"
                            ).format("YYYY-MM-DD HH:mm")
                          : "",
                      }
                    )
                  : this.props.createPayment(this.props.eventId, {
                      amount: this.state.amount,
                      way: this.state.way,
                      type: "accomodation",
                      typeId: this.props.item.id,
                      eventUserId: this.props.item.eventUserId,
                      transactionId: this.state.transactionId,
                      notes: this.state.notes,
                      transactionDate: this.state.transactionDate
                        ? moment(
                            this.state.transactionDate,
                            "DD-MM-YYYY HH:mm"
                          ).format("YYYY-MM-DD HH:mm")
                        : "",
                    });
              }}
              onCancel={() => {
                this.props.hideModal();
              }}
            />
          </>
        )}
        {this.state.mainType === "sponsorship" && (
          <>
            <div className="form-group">
              <label>Transaction Amount (*)</label>
              <Input
                type="number"
                placeholder="Enter the sponsored amount..."
                value={this.state.amount}
                onChange={(e) => {
                  if (!isFinite(e.target.value)) return;
                  this.setState({ amount: e.target.value });
                }}
              />
            </div>
            <div className="form-group">
              <label>Select a Sponsor (*)</label>
              <Select
                options={this.state.sponsorOptions}
                onChange={(val) => {
                  this.loadPersons(val);
                  this.setState({ sponsorId: val });
                }}
                isClearable={false}
                value={this.state.sponsorId}
              />
            </div>
            <div className="form-group">
              <label>Select a Person (*)</label>
              <Select
                options={this.state.personOptions}
                onChange={(val) => {
                  this.setState({ personId: val });
                }}
                isClearable={false}
                value={this.state.personId}
              />
            </div>
            <div className="form-group">
              <label>Type of sponsorship(*)</label>
              <Select
                options={{ credit: "Credit", deposit: "Deposit" }}
                onChange={(val) => {
                  this.setState({ sponsorshipType: val });
                }}
                isClearable={false}
                isSearchable={false}
                value={this.state.sponsorshipType}
              />
            </div>
            {this.state.sponsorshipType === "deposit" && (
              <div className="form-group">
                <label>Deposit expiration date(*)</label>
                <Datetime
                  dateFormat={"DD-MM-YYYY"}
                  timeFormat={false}
                  closeOnSelect={true}
                  value={this.state.sponsorshipExpirationDate}
                  inputProps={{
                    placeholder: `Expiration date`,
                  }}
                  onChange={(value) => {
                    if (!moment(value).isValid()) return;
                    this.setState({ sponsorshipExpirationDate: value });
                  }}
                />
              </div>
            )}
            <SubmitContainer
              onSubmit={() => {
                this.defaultMainType === "sponsorship"
                  ? this.props.updateSponsorship(
                      this.props.eventId,
                      this.state.sponsorshipId,
                      {
                        orgSponsorId: this.state.sponsorId,
                        orgSponsorPersonId: this.state.personId,
                        paymentType: this.state.sponsorshipType,
                        paymentDate: this.state.sponsorshipExpirationDate
                          ? moment(
                              this.state.sponsorshipExpirationDate,
                              "DD-MM-YYYY"
                            ).format("YYYY-MM-DD")
                          : "",
                        amount: this.state.amount,
                      }
                    )
                  : this.props.createSponsorship(this.props.eventId, {
                      type: "accomodation",
                      eventUserId: this.props.item.eventUserId,
                      referenceId: this.props.item.id,
                      orgSponsorId: this.state.sponsorId,
                      orgSponsorPersonId: this.state.personId,
                      paymentType: this.state.sponsorshipType,
                      paymentDate: this.state.sponsorshipExpirationDate
                        ? moment(
                            this.state.sponsorshipExpirationDate,
                            "DD-MM-YYYY"
                          ).format("YYYY-MM-DD")
                        : "",
                      amount: this.state.amount,
                    });
              }}
              onCancel={() => {
                this.props.hideModal();
              }}
            />
          </>
        )}
      </div>
    );
  }
}

PaymentType.propTypes = {
  eventId: PropTypes.number.isRequired,
  orgId: PropTypes.number.isRequired,
  loadSponsors: PropTypes.func.isRequired,
  sponsorList: PropTypes.any,
  getPersons: PropTypes.func,
  item: PropTypes.object,
  policyId: PropTypes.number,
  reservation: PropTypes.bool,
  createPayment: PropTypes.func,
  updatePayment: PropTypes.func,
  bankSupported: PropTypes.number,
  searchUsers: PropTypes.func,
  advancedUsers: PropTypes.array,
  accessEvents: PropTypes.object,
  registration: PropTypes.bool,
  loadPersons: PropTypes.func,
  hideModal: PropTypes.func,
  updateSponsorship: PropTypes.func,
  createSponsorship: PropTypes.func,
};
const mapStateToProps = (state) => {
  return {
    orgId: state.api.events.edit.data.orgId,
    sponsorList: state.api.sponsors.data,
    ready: state.api.sponsors.ready,
    eventId: state.api.events.edit.data.id,
    bankSupported: state.api.events.edit.data.settings.billing.bank,
    advancedUsers: state.users.advancedUsers.data,
    accessEvents: state.appuser.data.accessEvents,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadSponsors: (orgId) => {
      return dispatch(getSponsors(orgId, { rpp: -1 }));
    },
    loadPersons: (orgId, sponsorId) => {
      return dispatch(getPersons(orgId, sponsorId));
    },
    createSponsorship: (eventId, data) => {
      return dispatch(createSponsorship(eventId, data)).then(() =>
        dispatch(getReservations(eventId))
      );
    },
    updateSponsorship: (eventId, sponsorshipId, data) => {
      return dispatch(updateSponsorship(eventId, sponsorshipId, data)).then(
        () => dispatch(getReservations(eventId))
      );
    },
    createPayment: (eventId, data) => {
      return dispatch(createFastPayment(eventId, data)).then(() =>
        dispatch(getReservations(eventId))
      );
    },
    updatePayment: (eventId, paymentId, data) => {
      dispatch(updateFastPayment(eventId, paymentId, data)).then(() =>
        dispatch(getReservations(eventId))
      );
    },
    hideModal: () => dispatch(hideModal()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PaymentType);
