import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getPresetEmails, updateEmailSchedulings } from "@actions/presetEmails";
import Datetime from "react-datetime";
import {
  hideModal,
  formChanged,
  clearChanges,
  addNotification,
} from "@actions";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import NumberInput from "@layout/NumberInput";
import { scheduledEmails } from "../../helpers/scheduled-email-helper";
import Select from "@layout/Select2";
import Tooltip from "@material-ui/core/Tooltip";

const ScheduledEmailsModal = (props) => {
  const tooltipMessage =
    "This scheduling is invalid because it's in the past. Please change this scheduling.";

  const [schedulingArray, setSchedulingArray] = React.useState([]);
  const [arrayChanged, setArrayChanged] = React.useState(false);
  const [showErrors, setShowErrors] = React.useState(false);

  React.useEffect(() => {
    props.clearChanges();
  }, []);

  React.useEffect(() => {
    const incomingSchedulingArr = [];
    if (props.scheduling) {
      Object.entries(props.scheduling).forEach(([, value]) => {
        const newVal = JSON.parse(JSON.stringify(value));
        if (scheduledEmails.isStatic(newVal)) {
          newVal.onDate = moment(
            newVal.scheduledDatetime,
            scheduledEmails.momentFormat
          ).format(scheduledEmails.dateFormat);
          newVal.atTime = moment(
            newVal.scheduledDatetime,
            scheduledEmails.momentFormat
          ).format(scheduledEmails.timeFormat);
          delete newVal.scheduledDatetime;
        }

        if (newVal.lastScheduledSent) return;

        incomingSchedulingArr.push({
          sortLabel: `${scheduledEmails
            .parseDate(newVal, props.event)
            .format("Do MMMM  YY - HH:mm")}`,
          scheduling: JSON.parse(JSON.stringify(newVal)),
          id: newVal.id,
          isInPast: false,
        });
      });
    }
    const sortedSchedulingOptions = incomingSchedulingArr.sort((a, b) =>
      moment(a.sortLabel, "Do MMMM  YY - HH:mm").diff(
        moment(b.sortLabel, "Do MMMM  YY - HH:mm"),
        "minutes"
      )
    );

    setSchedulingArray(sortedSchedulingOptions);
  }, [props.scheduling]);

  React.useEffect(() => {
    if (arrayChanged) {
      setArrayChanged(false);
    }
  }, [arrayChanged]);

  const timeFlags = {
    startOfEvent: {
      label: "Start of event",
      dateTime: "",
    },
    endOfEvent: {
      label: "End of event",
      dateTime: "",
    },
  };

  const createSelectionTimeFlags = () => {
    const duplicateFlags = Object.assign({}, timeFlags);
    Object.entries(duplicateFlags).forEach((entry) => {
      duplicateFlags[entry[0]] = entry[1].label;
    });

    return duplicateFlags;
  };

  const isSubmitDisabled = () => {
    let disabled = false;

    const unsentOriginalSchedulings = props.scheduling?.filter((scheduling) => {
      if (scheduling.lastScheduledSent === null) {
        return true;
      } else {
        return false;
      }
    });

    if (
      (unsentOriginalSchedulings?.length === 0 ||
        unsentOriginalSchedulings?.length === undefined) &&
      schedulingArray?.length === 0
    ) {
      return true;
    }

    schedulingArray.forEach((arr_entry) => {
      if (scheduledEmails.isDateInThePast(arr_entry.scheduling, props.event)) {
        disabled = true;
        arr_entry.isInPast = true;
      }
    });
    return disabled;
  };

  const onSubmit = () => {
    props.clearChanges();

    const dataToSend = schedulingArray.map((arr_entry) => {
      if (scheduledEmails.isStatic(arr_entry.scheduling)) {
        arr_entry.scheduling.scheduledDatetime = `${arr_entry.scheduling.onDate} ${arr_entry.scheduling.atTime}`;
        delete arr_entry.scheduling.onDate;
        delete arr_entry.scheduling.atTime;
      }
      return arr_entry.scheduling;
    });

    props
      .updateEmailSchedulings(
        dataToSend,
        props.event.orgId,
        props.emailId,
        props.eventId
      )
      .then(() => {
        props.addNotification(
          "Email schedulings updated successfully.",
          "success"
        );
        props.getPresetEmails(props.event.orgId, props.event.id);
      });
  };

  const createNewScheduling = (type) => {
    props.formChanged();

    const newEntry = {
      id: `new-${Math.floor(Math.random() * 100) + 0.5}`,
      scheduling: {},
      sortLabel: null,
    };
    const newScheduling = {};
    newScheduling.presetEmailId = props.emailId;
    const updatedArr = schedulingArray;
    if (type === "static") {
      newScheduling.atTime = moment().format("HH:mm");
      newScheduling.onDate = moment().add(1, "day").format("YYYY-MM-DD");
    } else if (type === "dynamic") {
      newScheduling.timeEvent = "startOfEvent";
      newScheduling.numOfDays = 1;
      newScheduling.sequence = "after";
    }

    newEntry.scheduling = newScheduling;
    updatedArr.push(newEntry);
    setSchedulingArray(updatedArr);
    setArrayChanged(true);
  };

  const removeScheduling = (index) => {
    props.formChanged();

    let newArr = schedulingArray;
    newArr = newArr.slice(0, index).concat(newArr.slice(index + 1));
    setSchedulingArray(newArr);
    setArrayChanged(true);
    props.formChanged();
  };

  const getTimeValue = (scheduling) => {
    if (scheduling.numOfDays) return scheduling.numOfDays;
    if (scheduling.numOfHours) return scheduling.numOfHours;
    if (scheduling.numOfMins) return scheduling.numOfMins;
    return 0;
  };

  const getTimeUnit = (scheduling) => {
    if (scheduling.numOfDays !== null && scheduling.numOfDays !== undefined) {
      return "days";
    } else if (
      scheduling.numOfHours !== null &&
      scheduling.numOfHours !== undefined
    ) {
      return "hours";
    } else if (
      scheduling.numOfMins !== null &&
      scheduling.numOfMins !== undefined
    ) {
      return "minutes";
    }
    return "days";
  };

  const showSingleError = (index) => {
    if (schedulingArray[index].isInPast && showErrors) {
      return true;
    }
    return false;
  };

  const renderStaticInput = (scheduling, index) => {
    return (
      <div className="scheduling-input-wrapper">
        <div className="scheduling-input-label">Static Scheduling</div>
        <div className="scheduling-input-estimate">
          {scheduledEmails.computeScheduledEstimate(
            scheduledEmails.parseDate(scheduling, props.event),
            props.event
          )}
          {scheduledEmails.isDateInThePast(scheduling, props.event) ? (
            <Tooltip title={tooltipMessage}>
              <span className="past-date-warning icon-notification-1 email-positioner" />
            </Tooltip>
          ) : (
            ""
          )}
        </div>
        <div className="scheduling-input-fields static">
          <Datetime
            className="timepicker-container"
            dateFormat={"YYYY-MM-DD"}
            timeFormat={false}
            isValidDate={(currentDate) => {
              return moment().subtract(1, "day").isBefore(currentDate);
            }}
            inputProps={{ placeholder: "From", readOnly: false }}
            value={scheduling.onDate}
            onChange={(value) => {
              props.formChanged();
              if (typeof value === "string") {
                return;
              }
              const newSchedulingArray = schedulingArray;
              newSchedulingArray[index].scheduling.onDate =
                value.format("YYYY-MM-DD");
              newSchedulingArray[index].isInPast =
                scheduledEmails.isDateInThePast(
                  newSchedulingArray[index].scheduling,
                  props.event
                );
              setArrayChanged(true);
              setSchedulingArray(newSchedulingArray);
            }}
          />
          <Datetime
            className="timepicker-container"
            dateFormat={false}
            timeFormat={"HH:mm"}
            inputProps={{ placeholder: "From", readOnly: false }}
            value={scheduling.atTime}
            onChange={(value) => {
              props.formChanged();
              if (typeof value === "string") {
                return;
              }
              const newSchedulingArray = schedulingArray;
              newSchedulingArray[index].scheduling.atTime =
                value.format("HH:mm");
              newSchedulingArray[index].isInPast =
                scheduledEmails.isDateInThePast(
                  newSchedulingArray[index].scheduling,
                  props.event
                );
              setArrayChanged(true);
              setSchedulingArray(newSchedulingArray);
            }}
          />
        </div>
      </div>
    );
  };

  const renderDynamicInput = (scheduling, index) => {
    return (
      <div className="scheduling-input-wrapper">
        <div className="scheduling-input-label">Dynamic Scheduling</div>
        <div className="scheduling-input-estimate">
          {scheduledEmails.computeScheduledEstimate(
            scheduledEmails.parseDate(scheduling, props.event),
            props.event
          )}
          {scheduledEmails.isDateInThePast(scheduling, props.event) ? (
            <Tooltip title={tooltipMessage}>
              <span className="past-date-warning icon-notification-1 email-positioner" />
            </Tooltip>
          ) : (
            ""
          )}
        </div>
        <div className="scheduling-input-fields dynamic">
          Scheduled for
          <NumberInput
            min={0}
            inputtype="tel"
            value={getTimeValue(scheduling)}
            onChange={(value) => {
              props.formChanged();
              const newSchedulingArray = schedulingArray;
              if (
                scheduling.numOfDays !== null &&
                scheduling.numOfDays !== undefined
              ) {
                newSchedulingArray[index].scheduling.numOfDays = value;
              } else if (
                scheduling.numOfHours !== null &&
                scheduling.numOfHours !== undefined
              ) {
                newSchedulingArray[index].scheduling.numOfHours = value;
              } else if (
                scheduling.numOfMins !== null &&
                scheduling.numOfMins !== undefined
              ) {
                newSchedulingArray[index].scheduling.numOfMins = value;
              }
              newSchedulingArray[index].isInPast =
                scheduledEmails.isDateInThePast(
                  newSchedulingArray[index].scheduling,
                  props.event
                );
              setArrayChanged(true);
              setSchedulingArray(newSchedulingArray);
            }}
          />
          <Select
            className="units-select sentence-select"
            options={{
              days: "days",
              hours: "hours",
              minutes: "minutes",
            }}
            onChange={(value) => {
              props.formChanged();
              const newSchedulingArray = schedulingArray;
              const timeVal = getTimeValue(scheduling);
              delete newSchedulingArray[index].scheduling.numOfDays;
              delete newSchedulingArray[index].scheduling.numOfHours;
              delete newSchedulingArray[index].scheduling.numOfMins;

              if (value === "days") {
                newSchedulingArray[index].scheduling.numOfDays = timeVal;
              } else if (value === "hours") {
                newSchedulingArray[index].scheduling.numOfHours = timeVal;
              } else if (value === "minutes") {
                newSchedulingArray[index].scheduling.numOfMins = timeVal;
              }
              newSchedulingArray[index].isInPast =
                scheduledEmails.isDateInThePast(
                  newSchedulingArray[index].scheduling,
                  props.event
                );
              setArrayChanged(true);
              setSchedulingArray(newSchedulingArray);
            }}
            isClearable={false}
            value={getTimeUnit(scheduling)}
          />
          <Select
            className="sequence-select sentence-select"
            options={{ before: "before", after: "after" }}
            onChange={(value) => {
              props.formChanged();
              const newSchedulingArray = schedulingArray;
              newSchedulingArray[index].scheduling.sequence = value;
              newSchedulingArray[index].isInPast =
                scheduledEmails.isDateInThePast(
                  newSchedulingArray[index].scheduling,
                  props.event
                );
              setArrayChanged(true);
              setSchedulingArray(newSchedulingArray);
            }}
            isClearable={false}
            value={scheduling.sequence}
          />
          the
          <Select
            className="timeflag-select sentence-select"
            options={createSelectionTimeFlags()}
            onChange={(value) => {
              props.formChanged();
              const newSchedulingArray = schedulingArray;
              newSchedulingArray[index].scheduling.timeEvent = value;
              newSchedulingArray[index].isInPast =
                scheduledEmails.isDateInThePast(
                  newSchedulingArray[index].scheduling,
                  props.event
                );
              setArrayChanged(true);
              setSchedulingArray(newSchedulingArray);
            }}
            isClearable={false}
            value={scheduling.timeEvent}
          />
        </div>
      </div>
    );
  };

  const submitDisabled = isSubmitDisabled();

  return (
    <div className="form-container scheduler-container">
      <h2>Schedule Email</h2>
      <div className="scheduler-wrapper">
        {schedulingArray.map((scheduling, index) => {
          return (
            <div
              className={`scheduling-input-container ${
                scheduledEmails.isStatic(scheduling.scheduling)
                  ? "static"
                  : "dynamic"
              } ${showSingleError(index) ? "error-input" : ""}`}
              key={`${scheduling.id}`}
            >
              {scheduledEmails.isStatic(scheduling.scheduling)
                ? renderStaticInput(scheduling.scheduling, index)
                : renderDynamicInput(scheduling.scheduling, index)}
              <button
                className="btn circle remove-condition email"
                onClick={() => {
                  removeScheduling(index);
                }}
              >
                <i className="fa fa-times-circle"></i>
              </button>
            </div>
          );
        })}
        <div className="scheduler-new-date">
          <div
            onClick={() => createNewScheduling("static")}
            className="new-static-scheduling"
          >
            <span className="icon-add-1"></span>
            New static scheduling
          </div>
          <div
            className="new-dynamic-scheduling"
            onClick={() => createNewScheduling("dynamic")}
          >
            <span className="icon-add-1"></span>
            New dynamic scheduling
          </div>
        </div>
        <div className="scheduler-actions-wrapper">
          <div
            className="scheduler-action cancel-button"
            onClick={props.hideModal}
          >
            Cancel
          </div>
          <div
            className={`scheduler-action save-button`}
            onClick={() => {
              if (submitDisabled) {
                setShowErrors(true);
                return;
              }
              onSubmit();
            }}
          >
            Save
          </div>
        </div>
      </div>
    </div>
  );
};

ScheduledEmailsModal.propTypes = {
  formChanged: PropTypes.func,
  clearChanges: PropTypes.func,
  updateEmailSchedulings: PropTypes.func,
  addNotification: PropTypes.func,
  hideModal: PropTypes.func,
  getPresetEmails: PropTypes.func,
  eventId: PropTypes.number,
  event: PropTypes.object,
  scheduling: PropTypes.array,
  emailId: PropTypes.number,
};

ScheduledEmailsModal.defaultProps = {};

const mapStateToProps = (state) => {
  return {
    eventId: state.api.events.edit.data.id,
    event: state.api.events.edit.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPresetEmails: (orgId, eventId) => {
      return dispatch(getPresetEmails(orgId, eventId));
    },
    hideModal: () => {
      dispatch(hideModal());
    },
    updateEmailSchedulings: (data, orgId, emailId, eventId) => {
      return dispatch(updateEmailSchedulings(data, orgId, emailId, eventId));
    },
    formChanged: (form) => {
      dispatch(formChanged(form));
    },
    clearChanges: (form) => {
      dispatch(clearChanges(form));
    },
    addNotification: (notification, status) => {
      dispatch(addNotification(notification, status));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduledEmailsModal);
