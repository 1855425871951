import React from "react";
import Datetime from "react-datetime";
import Switch from "@layout/Switch";
import Select from "@layout/Select2";
import Radio from "@layout/Radio";
import Password from "@layout/Password";
import InputRange from "react-input-range";
import DateRange from "@layout/DateRange";
import ImageCover from "@layout/ImageCover";
import SessionType from "@components/form/partials/SessionType";
import Speaker from "@components/form/partials/Speaker";
import Checkboxes from "@layout/Checkboxes";
import SearchUser from "@layout/SearchUser";
import KMBDropzone from "@layout/KMBDropzone";
import "react-input-range/lib/css/index.css";
import Subscription from "@components/form/partials/Subscription";
import Iban from "@layout/Iban";
import Address from "@components/form/partials/Address";
import Rating from "@components/form/partials/Rating";
import Checkbox from "@layout/Checkbox";
import KMBEditor from "@layout/KMBEditor";
import TagsInput from "react-tagsinput";
import Autocomplete from "../components/layout/Autocomplete";
import { RegexPatterns } from ".";
import "react-tagsinput/react-tagsinput.css";
import moment from "moment";
import Input from "../components/layout/Input";

const formHelper = {
  fields: {},

  sliders: {},

  errors: {},

  errorTypes: {
    required: "This field is required.",
    invalid: "This field is invalid.",
  },

  renderFields: (fields, grouped, sliders, errors) => {
    formHelper.fields = fields;
    formHelper.sliders = sliders;
    formHelper.errors = errors;

    const preparedFields = [];

    Object.keys(fields).forEach((key) => {
      const field = fields[key];
      let children = [];
      const childErrors = {};

      if (field.type === "group") {
        children = field.fields;
        Object.keys(children).forEach((childKey) => {
          if (errors[childKey]) {
            childErrors[childKey] = errors[childKey];
          }
        });
      } else if (errors[key]) {
        childErrors[key] = errors[key];
      }

      const fieldWithErrors = { ...field, errors: childErrors };
      preparedFields.push({
        id: key,
        children,
        jsx: formHelper.getFieldMarkup(fieldWithErrors, key),
        condition: field.condition || false,
        conditionSchema: field.conditionSchema || false,
      });
    });

    return preparedFields;
  },

  getValue: (key, ref = false) => {
    if (!formHelper.fields.hasOwnProperty(key)) {
      return false;
    }

    const field = formHelper.fields[key];

    if (ref) {
      // check for current val

      switch (field.type) {
        case "switch": {
          return ref.state.isActive;
        }

        case "select": {
          if (ref.state.selections.length > 0) {
            return ref.state.selections[0];
          }
          return false;
        }
      }
    }

    if (formHelper.fields[key].hasOwnProperty("value")) {
      return formHelper.fields[key].value;
    }

    return false;
  },

  resetForm: () => {
    return formHelper.renderFields(formHelper.fields);
  },

  getData: (field) => {
    const data = {
      type: field.type,
      required: field.required,
      hasTime: field.hasTime || false,
      isSearchable: field.isSearchable || false,
      name: field.name,
      id: field.hasOwnProperty("id") && field.id ? field.id : field.name,
      label: field.hasOwnProperty("label") && field.label ? field.label : false,
      className:
        field.hasOwnProperty("className") && field.className
          ? field.className
          : "",
      placeholder:
        field.hasOwnProperty("placeholder") && field.placeholder
          ? field.placeholder
          : "",
      inputClass:
        field.hasOwnProperty("inputClass") && field.inputClass
          ? ` ${field.inputClass}`
          : "",
      divClass:
        field.hasOwnProperty("divClass") && field.divClass
          ? ` ${field.divClass}`
          : "",
      subtitle:
        field.hasOwnProperty("subtitle") && field.subtitle
          ? field.subtitle
          : "",
      value: field.hasOwnProperty("value") ? field.value : "",
      condition:
        field.hasOwnProperty("condition") && field.condition
          ? field.condition
          : "",
      conditionSchema:
        field.hasOwnProperty("conditionSchema") && field.conditionSchema
          ? field.conditionSchema
          : "",
      options: field.hasOwnProperty("options") ? field.options : false,
      disabled: field.hasOwnProperty("disabled") ? field.disabled : false,
      onChange: field.onChange || (() => {}),
      callback: field.callback || (() => {}),
    };
    if (field.type === "range") {
      data.from = field.hasOwnProperty("from") ? field.from : null;
      data.to = field.hasOwnProperty("to") ? field.to : null;
      data.value =
        field.hasOwnProperty("value") && field.value !== ""
          ? field.value
          : { from: new Date(), to: null };
      data.hasTime = field.hasOwnProperty("hasTime") && field.hasTime === true;
      data.hasDuration =
        field.hasOwnProperty("hasDuration") && field.hasDuration === true;
      data.fromTime =
        field.hasOwnProperty("fromTime") && field.fromTime
          ? field.fromTime
          : false;
      data.toTime =
        field.hasOwnProperty("toTime") && field.toTime ? field.toTime : false;
      data.min = field.hasOwnProperty("min") && field.min ? field.min : null;
      data.max = field.hasOwnProperty("max") && field.max ? field.max : null;
      data.autoTime =
        field.hasOwnProperty("autoTime") &&
        field.hasOwnProperty("hasTime") &&
        field.autoTime === true;
    }

    if (field.type === "switch") {
      if (!data.value) {
        data.value = 0;
      }
    }

    if (field.type === "dropzone") {
      data.multiple = field.hasOwnProperty("multiple") && field.multiple;
      data.styleType =
        field.hasOwnProperty("styleType") &&
        ["advanced", "simple"].includes(field.styleType)
          ? field.styleType
          : "advanced";
      data.height =
        field.hasOwnProperty("height") && field.height ? field.height : "auto";
      if (!data.value) {
        data.value = [];
      }
      if (field.hasOwnProperty("accept") && field.accept) {
        data.accept = field.accept;
      }
      if (field.hasOwnProperty("max")) {
        data.max = field.max;
      }
    }

    if (field.type === "slider") {
      data.minValue = field.minValue ? field.minValue : 0;
      data.maxValue = field.maxValue ? field.maxValue : 100;
      data.unit = field.unit ? field.unit : "px";
    }

    if (field.type === "address") {
      data.withMap = field.hasOwnProperty("withMap") && field.withMap;
    }

    if (field.type === "number") {
      data.min = field.min ? field.min : "";
    }

    if (field.type === "textarea") {
      data.rows = field.rows ? field.rows : 5;
    }

    if (field.type === "rating" && data.value === "") {
      data.value = 3;
    }

    if (field.type === "address" && data.value === "") {
      delete data.value;
    }

    if (field.type === "sessionType" || field.type === "speaker") {
      data.mode = field.hasOwnProperty("mode") ? field.mode : "existing";
    }

    if (field.type === "subscription") {
      data.subtype =
        field.hasOwnProperty("subtype") && field.subtype.hasOwnProperty("mode")
          ? field.subtype.mode
          : {
              mode: "existing",
            };
      data.subperiod =
        field.hasOwnProperty("subperiod") &&
        field.subperiod.hasOwnProperty("mode")
          ? field.subperiod.mode
          : {
              mode: "existing",
            };
      data.subperiodsOptions = field.subperiodsOptions;
      data.subtypesOptions = field.subtypesOptions;
    }

    if (field.type === "select") {
      data.multi = field.hasOwnProperty("multi") && field.multi;
    }

    if (field.type === "tagsInput" && field.name === "emailsValue") {
      data.isEmail = true;
    }

    if (field.type === "searchUser") {
      const data = {
        type: field.type,
        name: field.name,
        disabled: field.disabled,
        multi: field.multi,
        inputClass: field.inputClass,
        id: field.id,
        required: field.required,
        placeholder: field.placeholder,
        subtitle: field.subtitle,
        label: field.label,
        options: field.options,
        callback: field.callback,
        onChange: field.onChange,
        value: field.value,
        filters: {
          callback: () => {},
          options: [],
        },
      };

      if (field.hasOwnProperty("filters")) {
        if (typeof field.filters.callback === "function") {
          data.filters.callback = field.filters.callback;
        }
        if (Array.isArray(field.filters.options)) {
          data.filters.options = field.filters.options;
        }
      }

      return data;
    }

    return data;
  },

  getWrapperMarkup: (data, isRequired, error) => {
    let baseClassName = `field-type type-${data.type} kmb-${data.type}`;

    if (data.type === "switch") {
      baseClassName += " clearfix";
    }

    const errorClass = error && error.length > 0 ? "has-error" : "";
    const combinedClassName =
      `${baseClassName} ${data.className} ${errorClass}`.trim();

    return (
      <div className={combinedClassName}>
        {data.label && (
          <label htmlFor={data.id}>
            {data.label}
            {isRequired && <span> (*)</span>}
          </label>
        )}
        {data.subtitle && <span className="subtitle">{data.subtitle}</span>}
        {formHelper.generateField(data)}
        {error && <div className="help-block">{error}</div>}
      </div>
    );
  },

  getFieldMarkup: (field) => {
    let data;

    if (field.type === "group") {
      const jsx = [];

      Object.entries(field.fields).map(([key, child]) => {
        data = formHelper.getData(child);
        const childError = formHelper.errors[key];
        const col = (
          <div className={field.className || "col-sm-6"} key={key}>
            {formHelper.getWrapperMarkup(data, undefined, childError)}
          </div>
        );

        jsx.push(col);
      });

      return (
        <div>
          {field.label && <label htmlFor={field.name}>{field.label}</label>}
          <div className="row">{jsx}</div>
        </div>
      );
    }
    data = formHelper.getData(field);
    const errorForField = formHelper.errors[field.name];
    return formHelper.getWrapperMarkup(data, field.required, errorForField);
  },

  generateField: (data) => {
    switch (data.type) {
      case "checkboxes": {
        return (
          <Checkboxes
            options={data.options}
            ref={data.name}
            value={data.value}
            onChange={data.onChange}
            name={data.name}
          />
        );
      }

      case "checkbox": {
        return <Checkbox />;
      }
      case "dropzone": {
        return (
          <KMBDropzone
            ref={data.name}
            {...data}
            onFileDelete={(file) =>
              data.onChange(data.name, file, "fileDelete")
            }
          />
        );
      }
      case "imageCover": {
        return (
          <ImageCover
            height={data.height || 200}
            imageUrl={data.value[0]?.url}
            ref={data.name}
            {...data}
          />
        );
      }
      case "tagsInput": {
        const handlePaste = (event) => {
          event.preventDefault();
          const pastedData = event.clipboardData.getData("Text");
          const tags = pastedData.split(/[\s,]+/).map((tag) => tag.trim());
          const newValue = [...data.value, ...tags];
          data.onChange(data.name, newValue);
        };

        const renderTag = ({ tag, key, onRemove }) => {
          let className = "react-tagsinput-tag";
          if (data.isEmail && !RegexPatterns.EMAIL.test(tag)) {
            className += " has-error";
          }
          return (
            <span key={key} className={className}>
              {tag}
              <a
                className="react-tagsinput-remove"
                onClick={() => onRemove(key)}
              />
            </span>
          );
        };

        return (
          <TagsInput
            value={data.value}
            className={`react-tagsinput form-group`}
            name={data.name}
            onChange={(tags) => {
              data.onChange(data.name, tags);
            }}
            inputProps={{
              className: "react-tagsinput-input form-control",
              placeholder: data.placeholder,
              onPaste: handlePaste,
            }}
            onlyUnique
            addOnBlur
            addKeys={[188, 32]}
            renderTag={renderTag}
          />
        );
      }

      case "editor": {
        return <KMBEditor ref={data.name} {...data} />;
      }

      case "subscription": {
        const hasErrors = formHelper.errors.hasOwnProperty(data.name)
          ? true
          : false;

        return (
          <Subscription
            subtype={data.subtype}
            subperiod={data.subperiod}
            name={data.name}
            ref={data.name}
            onChange={data.onChange}
            subperiodsOptions={data.subperiodsOptions}
            subtypesOptions={data.subtypesOptions}
            hasErrors={hasErrors}
            value={data.value}
          />
        );
      }

      case "switch": {
        return (
          <div className="switch-holder" id={data.name}>
            <Switch
              id={data.name}
              ref={data.name}
              onChange={(value) => data.onChange(data.name, value)}
              callback={data.callback}
              isActive={parseInt(data.value)}
            />
          </div>
        );
      }

      case "sessionType": {
        return (
          <SessionType
            mode={data.mode}
            value={data.value}
            name={data.name}
            ref={data.name}
            onChange={data.onChange}
            options={data.options}
          />
        );
      }

      case "token": {
        return (
          <div>
            <input
              ref={data.name}
              type={data.type}
              className={`form-control${data.inputClass}`}
              name={data.name}
              placeholder={data.placeholder ? data.placeholder : ""}
              id={data.id}
              defaultValue={data.value}
              onChange={(e) => data.onChange(data.name, e.target.value)}
              readOnly={true}
            />
            <span>
              <span className="icon-copy-symbol"></span> Copy
            </span>
          </div>
        );
      }

      case "text":
      case "email": {
        return (
          <div>
            <input
              ref={data.name}
              type={data.type}
              className={`form-control${data.inputClass}`}
              name={data.name}
              placeholder={data.placeholder ? data.placeholder : ""}
              id={data.id}
              defaultValue={data.value}
              onChange={(e) => data.onChange(data.name, e.target.value)}
              disabled={data.disabled}
            />
            <span className="icon icon-edit"></span>
          </div>
        );
      }

      case "password": {
        return (
          <div ref={data.name}>
            <Password
              type={data.type}
              className={`form-control ${data.inputClass || ""}`}
              name={data.name}
              placeholder={data.placeholder || ""}
              id={data.id}
              value={data.value}
              onChange={(e) => data.onChange(data.name, e.target.value)}
              autoComplete="new-password"
            />
          </div>
        );
      }

      case "radio": {
        return (
          <Radio
            valueFromState={true}
            ref={data.name}
            id={data.id}
            name={data.name}
            options={data.options}
            disabled={data.disabled}
            checked={data.value ? data.value.toLowerCase() : undefined}
            onChange={(value) => {
              data.onChange(data.name, value);
            }}
          />
        );
      }

      case "number": {
        return (
          <div>
            <Input
              ref={data.name}
              type={data.type}
              className={`form-control${data.inputClass}`}
              name={data.name}
              placeholder={data.placeholder ? data.placeholder : ""}
              id={data.id}
              defaultValue={data.value}
              min={data.min}
              step=".01"
              onChange={data.onChange}
            />
            <span className="icon icon-edit"></span>
          </div>
        );
      }

      case "range": {
        const hasErrors = formHelper.errors.hasOwnProperty(data.name)
          ? true
          : false;
        if (!data.from.value && data.min !== null) {
          data.from.value = data.min;
        }
        return (
          <DateRange
            ref={data.name}
            from={data.from}
            to={data.to}
            fromTime={data.fromTime}
            toTime={data.toTime}
            hasErrors={hasErrors}
            onChange={data.onChange}
            hasTime={data.hasTime}
            hasDuration={data.hasDuration}
            min={data.min}
            max={data.max}
            autoTime={data.autoTime}
          />
        );
      }

      case "slider": {
        return (
          <div>
            <InputRange
              maxValue={data.maxValue}
              minValue={data.minValue}
              value={
                formHelper.sliders[data.name]
                  ? parseInt(formHelper.sliders[data.name])
                  : 0
              }
              ref={data.name}
              onChange={(val) => {
                data.onChange(data.name, { val, unit: data.unit });
              }}
            />
            <div className="outer-input">
              <input
                type="text"
                value={formHelper.sliders[data.name]}
                onChange={(e) => {
                  let val = e.target.value;
                  if (!val) val = 0;
                  if (val > data.maxValue) {
                    val = data.maxValue;
                  }
                  if (val < data.minValue) {
                    val = data.minValue;
                  }
                  val = parseInt(val);
                  data.onChange(data.name, { val, unit: data.unit });
                }}
              />
              <span>{data.unit}</span>
            </div>
          </div>
        );
      }

      case "iban": {
        return <Iban ref={data.name} {...data} />;
      }

      case "address": {
        const hasErrors = formHelper.errors.hasOwnProperty(data.name)
          ? true
          : false;
        return (
          <Address
            ref={data.name}
            {...data}
            hasErrors={hasErrors}
            isRequired={data.required}
          />
        );
      }

      case "rating": {
        return <Rating ref={data.name} {...data} />;
      }

      case "datetime": {
        return (
          <div>
            <Datetime
              ref={data.name}
              dateFormat="DD-MM-YYYY"
              initialValue={
                data.value
                  ? moment(data.value, "DD-MM-YYYY HH:mm").toDate()
                  : null
              }
              timeFormat="HH:mm"
              closeOnSelect={true}
              onChange={data.onChange}
              initialViewDate={new Date()}
            />
            <span className="icon icon-date"></span>
          </div>
        );
      }

      case "select": {
        return (
          <Select
            bypassPropsValue={true}
            ref={data.name}
            isSearchable={data.isSearchable}
            isClearable={!data.required}
            name={data.name}
            id={data.id}
            className={data.inputClass}
            options={data.options || {}}
            onChange={(value) => {
              data.onChange(data.name, value);
            }}
            value={data.value}
            multi={data.multi}
            disabled={data.disabled}
          ></Select>
        );
      }

      case "textarea": {
        return (
          <div>
            <textarea
              ref={data.name}
              name={data.name}
              id={data.id}
              className={`form-control${data.inputClass}`}
              placeholder={data.placeholder ? data.placeholder : ""}
              rows={data.rows}
              defaultValue={data.value}
              onChange={data.onChange}
              disabled={data.disabled}
            ></textarea>
            <span className="icon icon-edit"></span>
          </div>
        );
      }

      case "speaker": {
        const hasErrors = formHelper.errors.hasOwnProperty(data.name)
          ? true
          : false;
        return (
          <Speaker
            mode={data.mode}
            value={data.value}
            name={data.name}
            ref={data.name}
            onChange={data.onChange}
            options={data.options}
            hasErrors={hasErrors}
          />
        );
      }

      case "searchUser": {
        const hasErrors = formHelper.errors.hasOwnProperty(data.name)
          ? true
          : false;
        return (
          <SearchUser
            multi={data.multi}
            name={data.name}
            ref={data.name}
            options={data.options}
            placeholder={data.placeholder}
            value={data.value}
            disabled={data.disabled}
            filters={data.filters}
            onSearch={data.callback}
            onChange={data.onChange}
            hasErrors={hasErrors}
          />
        );
      }

      case "typeselect": {
        return (
          <div ref={data.name}>
            <Autocomplete
              formData={data}
              onChange={(value) => data.onChange(data.name, value)}
              name={data.name}
              id={data.id}
              className={`${data.inputClass}`}
              options={data.options}
            />
          </div>
        );
      }
    }
  },

  validateForm: (refs, grouped = {}) => {
    const errors = {};
    const values = {};
    let type = "json";
    Object.entries(refs).map((ref) => {
      let value = null;
      let error = null;
      let fieldObject = null;
      let element = ref[1];
      const refKey = ref[0];

      // I could not reference the input of Autocomplete correctly, so i refernced the wrapper
      // and then took the firstchild which is the input i want
      if (element.firstChild && ref[1].localName !== "textarea") {
        element = element.firstChild;
      }

      if (grouped.hasOwnProperty(refKey)) {
        fieldObject =
          formHelper.fields[grouped[refKey].group]["fields"][refKey];
        if (fieldObject.type === "datetime") {
          value = element.state.inputValue;
        } else if (fieldObject.type === "switch") {
          value = element.state.isActive;
        } else if (fieldObject.type === "searchUser") {
          value = element.state.value;
        } else if (fieldObject.type === "select" && refKey == "timezone") {
          value = element.state.selections[0];
        } else if (fieldObject?.type === "email") {
          value = element.value;
          if (!RegexPatterns.EMAIL.test(value) && value !== "")
            error = "invalid";
        } else {
          value = element.value;
        }
      } else {
        fieldObject = formHelper.fields[refKey];

        switch (fieldObject?.type) {
          case "datetime": {
            value = element.state.inputValue;
            break;
          }
          case "select": {
            if (element.props.multi === true) {
              value = element.state.selOption.map((option) => option.value);
            } else {
              /* new select */
              if (element.state.selected instanceof Array) {
                value =
                  element.state.selected.length > 0
                    ? element.state.selected[0].value
                    : "";
              } else {
                value = element.state.selected?.value || "";
              }
            }
            break;
          }
          case "switch": {
            value = element.state.isActive;
            break;
          }
          case "radio": {
            value = element.state.checked;
            break;
          }
          case "slider": {
            value = element.props.value;
            break;
          }
          case "searchUser": {
            value = element.state.value;
            break;
          }
          case "range": {
            value = element.state.range;

            if (formHelper.fields[refKey].from.required && !value.from) {
              value = null;
            }
            if (formHelper.fields[refKey].to.required && !value.to) {
              value = null;
            }
            if (
              formHelper.fields[refKey].hasOwnProperty("fromTime") &&
              formHelper.fields[refKey].fromTime === true &&
              !value.fromTime
            ) {
              value = null;
            }
            if (
              formHelper.fields[refKey].hasOwnProperty("toTime") &&
              formHelper.fields[refKey].toTime === true &&
              !value.toTime
            ) {
              value = null;
            }

            break;
          }
          case "sessionType": {
            const elementState = element.state;
            value = !elementState.value ? null : elementState;
            break;
          }

          case "speaker": {
            const elementState = element.state;
            if (elementState.mode === "existing") {
              value = elementState.value
                ? {
                    value: elementState.value,
                    mode: elementState.mode,
                  }
                : null;
            } else {
              value = elementState.newSpeaker;
              for (const v of value) {
                if (v.personThumbnail === false) {
                  delete v.personThumbnail;
                } else {
                  type = "form-data";
                }
                if (!v.firstName || !v.lastName) {
                  value = null;
                  break;
                }
              }

              if (value) {
                value = {
                  value: elementState.newSpeaker,
                  mode: elementState.mode,
                };
              }
            }
            break;
          }

          case "subscription": {
            value = {};

            for (const [key, val] of Object.entries(element.state)) {
              if (key === "subperiod" && val.mode !== "existing") {
                if (!val.value.date || !val.value.name) {
                  value = null;
                  break;
                }
              }
              if (!val.value) {
                value = null;
                break;
              } else {
                value[key] = val;
              }
            }
            break;
          }

          case "iban": {
            value = element.state.value;
            if (!element.state.isValid) error = "invalid";
            break;
          }

          case "checkboxes": {
            value = element.state.selected;
            break;
          }

          case "dropzone": {
            const { existingFiles, queuedFiles } = element.state;

            value = [...queuedFiles];

            if (
              fieldObject.required &&
              existingFiles.concat(queuedFiles).length === 0
            ) {
              errors[refKey] = ["This field is required."];
            }

            break;
          }

          case "editor": {
            break;
          }

          case "rating": {
            value = element.state.rating;
            break;
          }

          case "address": {
            value = element.state.location;
            for (const v of Object.entries(value)) {
              if (!v[1]) {
                value = null;
                break;
              }
            }
            break;
          }
          case "email": {
            value = element.value;
            if (!RegexPatterns.EMAIL.test(value) && value !== "") {
              error = "invalid";
            }
            break;
          }

          default: {
            value = element.value;
            break;
          }
        }
      }
      if (
        refKey === "sellingPrice" &&
        !RegexPatterns.TWO_DECIMAL_LIMIT.test(value)
      ) {
        if (!errors.hasOwnProperty(refKey)) errors[refKey] = new Array();
        errors[refKey].push("Selling Price must have up to 2 decimal places");
      } else if (
        refKey === "quantity" &&
        (!value || isNaN(value) || value <= 0)
      ) {
        if (!errors.hasOwnProperty(refKey)) errors[refKey] = new Array();
        errors[refKey].push("Quantity must be greater than 0");
      } else if (!value && fieldObject.required) {
        errors[refKey] = new Array();
        errors[refKey].push(formHelper.getErrorMessage("required"));
      } else if (error === "invalid") {
        if (!errors.hasOwnProperty(refKey)) errors[refKey] = [];
        errors[refKey].push(formHelper.getErrorMessage("invalid"));
      } else {
        // handle case of no error but value is empty when it should have a value
        // the case below is in schedule -> questions email
        if (refKey === "questionsEmail") {
          const isQuestionsEnabled = values["questions"];
          if (isQuestionsEnabled && !RegexPatterns.EMAIL.test(value)) {
            if (!errors.hasOwnProperty(refKey)) errors[refKey] = [];
            errors[refKey].push(formHelper.getErrorMessage("invalid"));
          } else values[refKey] = value;
        }
        values[refKey] = value;
      }
    });

    return { values, errors, type };
  },

  getErrorMessage: (type) => {
    if (formHelper.errorTypes.hasOwnProperty(type))
      return formHelper.errorTypes[type];
  },
};

export default formHelper;
